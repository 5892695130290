// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-pickups-calendar {}

.admin-pickups-calendar .occurrence * {
  line-height: 1 !important;
}

.admin-pickups-calendar .fc-h-event {
  font-style: normal;
  background-color: transparent;
  border: none;
  font-size: 0.7rem;
  height: auto !important;
  border-bottom: 0px solid #eee;
  border-radius: 0;
}

.admin-pickups-calendar .fc-h-event .fc-event-main {
  color: black;
}

.admin-pickups-calendar .switcher-on .handler {
  background-color: var(--bs-danger);
}

.admin-pickups-calendar .collect,
.admin-pickups-calendar .restitution {
  padding: 1px 0;
  width: 18px;
  text-align: center;
  display: inline-block;
}

.admin-pickups-calendar .collect {
  background-color: #046FB5;
  color: white;
}

.admin-pickups-calendar .restitution {
  background-color: #D9F371;
}`, "",{"version":3,"sources":["webpack://./src/components/pickups/admin/admin-pickups-calendar.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;;EAEE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".admin-pickups-calendar {}\r\n\r\n.admin-pickups-calendar .occurrence * {\r\n  line-height: 1 !important;\r\n}\r\n\r\n.admin-pickups-calendar .fc-h-event {\r\n  font-style: normal;\r\n  background-color: transparent;\r\n  border: none;\r\n  font-size: 0.7rem;\r\n  height: auto !important;\r\n  border-bottom: 0px solid #eee;\r\n  border-radius: 0;\r\n}\r\n\r\n.admin-pickups-calendar .fc-h-event .fc-event-main {\r\n  color: black;\r\n}\r\n\r\n.admin-pickups-calendar .switcher-on .handler {\r\n  background-color: var(--bs-danger);\r\n}\r\n\r\n.admin-pickups-calendar .collect,\r\n.admin-pickups-calendar .restitution {\r\n  padding: 1px 0;\r\n  width: 18px;\r\n  text-align: center;\r\n  display: inline-block;\r\n}\r\n\r\n.admin-pickups-calendar .collect {\r\n  background-color: #046FB5;\r\n  color: white;\r\n}\r\n\r\n.admin-pickups-calendar .restitution {\r\n  background-color: #D9F371;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
